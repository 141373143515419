import PureLogo from "./PureLogo";
import { useState } from "react";
import { BsList, BsX } from 'react-icons/bs';
// import { BsGlobe } from "react-icons/bs";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`fixed top-0 z-50 w-full border-solid border-b-[3px] border-lightgreen max-w-[100vw] overflow-x-hidden`}>
  <nav className={`bg-darkgreen text-white p-4 border-solid border-b-[3px] ${isOpen ? 'h-[144px]' : 'h-[72px]'} border-white flex items-center justify-center transition-all duration-500 ease-in-out`}>
    <div className="flex justify-between items-center w-full">
      <a href="#about" className={`${isOpen ? 'w-24' : 'w-24'} lg:ml-16`}>
        <PureLogo />
      </a>
      <div className={`text-xs md:text-lg flex ${isOpen ? 'flex-col sm:flex-row' : 'hidden sm:flex'} items-center font-semibold flex-grow sm:relative justify-center transition-all duration-500 ease-in-out`}>
            <a
              href="#about"
              className="mx-1 lg:mx-3 py-1 hover-effect"
              onClick={() => setIsOpen(!isOpen)}>
              ABOUT US
            </a>
            <a
              href="#products"
              className="mx-1 lg:mx-3 py-1 hover-effect"
              onClick={() => setIsOpen(!isOpen)}>
              OUR PRODUCT
            </a>
            <a
              href="#health"
              className="mx-1 lg:mx-3 py-1 hover-effect"
              onClick={() => setIsOpen(!isOpen)}>
              HEALTH
            </a>
            <a
              href="#sustainability"
              className="mx-1 lg:mx-3 py-1 hover-effect"
              onClick={() => setIsOpen(!isOpen)}>
              SUSTAINABILITY
            </a>
            <a
              href="#contact"
              className="mx-1 lg:mx-3 py-1 hover-effect"
              onClick={() => setIsOpen(!isOpen)}>
              CONTACT US
            </a>
          </div>
          <div className="flex sm:hidden">
            <button onClick={() => setIsOpen(!isOpen)} className="ml-auto">
              {isOpen ? <BsX size={32} /> : <BsList size={32} className="border-b-1 border-solid border-white border-2 rounded-md " />}
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
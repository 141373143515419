import { forwardRef } from "react";

const AboutUs = forwardRef((props, ref) => {
  return (
    <div className="mt-[72px]">
      <img id="about" ref={ref} className="w-full" src="/introductionv2.jpg" alt="Vegan Burger" />
      <div className="bg-lightgreen font-extrabold text-xs py-2 md:text-lg text-darkgreen px-6 xl:px-52 md:py-12">
        <div className=" relative z-10">
          <p>
            PURE & Vegan, where tradition meets innovation in the heart of Europe.
            Our mission is to revolutionize the way the world sees alternative proteins,
            offering delicious, nutritious, and sustainable food choices that cater to modern lifestyles.
          </p>
          <p>
            We created plant-based products that are not only high in protein but also free from common allergens like soy,
            gluten, and lactose and rich in fibre.
            Our products are primarily made from mushrooms, offering a unique, meat-like texture to impress both vegetarians and meat enthusiasts.
          </p>
          <p>
            Sustainability is at the core of everything we do. We believe in making choices that are kind to our planet,
            which is why our products are designed to reduce environmental impact without compromising on taste or quality.
          </p>
          <p>
            Join us on our mission to provide a purer, more sustainable way of living.
            With PURE & Vegan, you're not just choosing a meal; you're choosing a movement towards a healthier, more compassionate world.
          </p>
        </div>
        <div className="inset-0 z-0 w-full translate-y-[45%]">
          <img src="/p&v.png" alt="Floating" className='block w-[20%] absolute right-[-15%] -translate-y-[80%] -mt-6 sm:-mt-0 sm:scale-[2.1] scale-[3] mr-[20%] xl:mr-[10%] opacity-25 origin-center' />
        </div>
      </div>
    </div>
  );
})

export default AboutUs;
import { forwardRef } from "react";

const Sustainability = forwardRef((props, ref) => {
  return (
    <>
      <h2 id="sustainability" ref={ref} className="bg-white pl-3 sm:pl-16 text-darkgreen font-extrabold py-12 text-4xl sm:text-5xl">SUSTAINABILITY</h2>
      <div className="flex justify-center items-center bg-skin">
        <div className="flex flex-col md:flex-row items-center md:space-x-4">
          <div className="md:order-1 md:flex-1">
            <img
              src="/185957445_xl_normal_none_RET.jpg"
              alt="Sustainability"
              className="w-full h-auto object-cover"
            />
          </div>
          <div className="text-darkgreen text-xs lg:text-sm xl:text-lg text-justify font-bold p-3 md:p-6 md:order-2 md:flex-1">
            <div className="space-y-4 my-2 md:my-4 md:mr-[10%] 2xl:mr-40 relative z-10">
              <p>
                In terms of sustainability, PURE's choice of ingredients contributes to a more sustainable food system. Mushrooms and peas have a lower environmental footprint compared to traditional meat sources, requiring less water, land, and energy to produce. Additionally, these plants can enrich soil health and reduce greenhouse gas emissions. By focusing on plant-based ingredients, PURE is able to offer products that are not only healthy for consumers but also kinder to the planet.
              </p>
              <p>
                With PURE, there is no compromise on taste or health, allowing you to enjoy delicious and nutritious meals while contributing to a more sustainable food system.
              </p>
            </div>
            <div className="inset-0 z-0 w-full translate-y-[45%]">
              <img src="/plantbased.png" alt="Floating" className="block w-[28%] absolute right-[-10%] -translate-y-[95%] md:-translate-y-[110%] max-[400px]:scale-[2.1] scale-[1.2] md:scale-[2.2] mr-[20%] md:mr-[35%] opacity-20 origin-center" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Sustainability;
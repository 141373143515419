const ProductIngredient = ({
  bgColor,
  textColor,
  title,
  description,
  textOrientation,
  imgSrc,
  imgAlt,
  imgOrientation,
}) => {
  return (
    <div className="z-10">
      <div className={`flex relative ${bgColor} font-extrabold md:py-[5%] items-center justify-center flex-col md:flex-row`}>
        <div className={`flex md:absolute md:scale-[50%] md:flex-none md:w-1/8 justify-center items-center w-full md:w-auto z-20 md:order-2`}>
          <img src={imgSrc} alt={imgAlt} className={`w-full md:w-auto h-auto drop-shadow-2xl ${imgOrientation}`} />
        </div>
        <div className={`flex-1 px-5 flex justify-center mb-8 ${title === 'Salt' ? 'text-left': 'text-right'} items-center z-30 ${textColor} ${textOrientation} md:order-1`}>
          <div className="w-full md:w-[60%]">
            <h3 className="font-extrabold max-[400px]:text-lg text-2xl md:text-4xl md:text-left">{title}:</h3>
            <p className="max-[400px]:text-base text-xl md:text-2xl md:text-left">
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductIngredient;
import Ingredient from "./Ingredient"

const IngredientsList = () => {
  const ingredients = [
    {
      bgColor: 'bg-mushroombg',
      textColor: 'text-mushroomtxt',
      title: 'Mushrooms',
      description: ' A key component, mushrooms are low in calories and high in vitamins, minerals, and antioxidants, supporting overall health and immunity.',
      imgSrc: '/136558557_xl_normal_none.png',
      imgAlt: 'Mushrooms',
      imgOrientation: 'md:mr-[75%] rotate-[30deg] -mt-16 -mb-8 md:-rotate-12 shadow',
      textOrientation: 'text-left md:ml-[40%]',
    },
    {
      bgColor: 'bg-darkgreen',
      textColor: 'text-peagreen',
      title: 'Peas',
      description: 'A great source of plant-based protein and fiber, peas help keep you full and support digestive health.',
      imgSrc: '/148678618_xl_normal_none.png',
      imgAlt: 'Peas',
      imgOrientation: 'md:ml-[50%] -mt-12 -rotate-12',
      textOrientation: 'text-right md:mr-[40%] md:mb-20',
    },
    {
      bgColor: 'bg-onionbg',
      textColor: 'text-oniontxt',
      title: 'Onions',
      description: 'Rich in nutrients and antioxidants, onions add flavor while promoting heart health and reducing inflamation',
      imgSrc: '/104211710_xl_normal_none.png',
      imgAlt: 'Onions',
      imgOrientation: 'md:mr-[75%] md:-mt-8 -mt-12 rotate-[25deg] scale-105',
      textOrientation: 'text-left md:ml-[40%] md:mt-12 md:mb-8',
    },
    {
      bgColor: 'bg-cocobg',
      textColor: 'text-white',
      title: 'Coconut Oil',
      description: 'Provides healthy fats that can support heart health, metabolism, and skin health.',
      imgSrc: '/80566452_xl_normal_none_BAÁLL.png',
      imgAlt: 'Coconuts',
      imgOrientation: 'md:ml-[50%] -mt-8 scale-125',
      textOrientation: 'text-right md:mr-[50%] md:mb-28',
    },
    {
      bgColor: 'bg-beetrootbg',
      textColor: 'text-beetroottxt',
      title: 'Beetroot',
      description: 'Adds natural sweetness and color, and is known for its fiber content, vitamins, and minerals that benefit heart health and exercise performance.',
      imgSrc: '/119387631_xl_normal_none.png',
      imgAlt: 'Beetroot',
      imgOrientation: 'md:mr-[75%] -mt-12 md:-mt-40 -rotate-[12deg] scale-90 ',
      textOrientation: 'text-left md:ml-[38%] md:mt-8 md:mb-8',
    },
    {
      bgColor: 'bg-saltbg',
      textColor: 'text-salttxt',
      title: 'Salt',
      description: 'Used minimally to enhance flavor, our careful use of salt ensures that our products are both tasty and heart-friendly.',
      imgSrc: '/93156757_xl_normal_none.png',
      imgAlt: 'Salt',
      imgOrientation: 'md:ml-[50%] -mt-16 scale-x-[-1] md:scale-x-[-0.75] md:scale-75',
      textOrientation: 'text-left -mt-8 md:mr-[40%] pb-1 xl:-mb-10 md:mt-16 '
    }
  ];

  return (
    <>
      {ingredients.map((ingredient, index) => (
        <Ingredient key={index} {...ingredient} />
      ))}
    </>
  );
};

export default IngredientsList;
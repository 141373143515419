import { forwardRef } from "react";
import Product from "./Product";

const OurProducts = forwardRef((props, ref) => {
  const products = [
    {
      title: 'Pure Burger',
      imageSrc: 'PHOTO-2024-04-16-01-41-58_RET.jpg',
      altText: 'Pure Burger',
    },
    {
      title: 'Pure Balls',
      imageSrc: '/PHOTO-2024-04-16-01-42-00-1_RET.jpg',
      altText: 'Pure Balls',
    },
  ];

  return (
    <>
      <h2 id="products" ref={ref} className="bg-white pl-3 sm:pl-16 text-darkgreen font-black py-8 text-4xl sm:text-5xl">OUR PRODUCTS</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:mx-12 sm:gap-20 px-4 mx-3 sm:mx-2">
        {products.map((product) => (
          <Product key={product.altText} {...product} />
        ))}
      </div>
      <img src="/free.jpg" alt="soy-lactose-gluten-free" className="mx-auto scale-75 mb-6"></img>
    </>
  );
});

export default OurProducts;
import { useEffect, useState } from 'react';

function useScrollSpy(sectionRefs) {
    const [enableHashUpdate, setEnableHashUpdate] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            if (enableHashUpdate) setEnableHashUpdate(false);
            clearTimeout(window.hashUpdateTimeout);
            window.hashUpdateTimeout = setTimeout(() => setEnableHashUpdate(true), 100);
        };

        window.addEventListener('scroll', handleScroll);

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && enableHashUpdate) {
                        window.history.pushState({}, '', `#${entry.target.id}`);
                    }
                    if (window.location.hash === '#about') {
                        window.history.pushState("", document.title, window.location.pathname + window.location.search);
                    }
                });
            },
            { threshold: 0.5 }
        );

        sectionRefs.forEach(ref => {
            if (ref.current) observer.observe(ref.current);
        });

        return () => {
            sectionRefs.forEach(ref => {
                if (ref.current) observer.unobserve(ref.current);
            });
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(window.hashUpdateTimeout);
        };
    }, [sectionRefs, enableHashUpdate]);
}

export default useScrollSpy;
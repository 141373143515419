// import { FaFacebookF, FaTiktok } from 'react-icons/fa';
// import { FaInstagram } from "react-icons/fa6";

export default function Footer() {
  return (
    <footer className="w-full mt-14">
      {/* <div className=" bg-lightgreen py-6 px-4 text-2xl overflow-hidden sm:px-6 lg:px-8">
        <nav className=" flex flex-wrap justify-center" aria-label="Footer">
          <div className="py-2 flex justify-center items-center">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="w-7 h-7 bg-white hover:bg-peagreen flex justify-center items-center rounded-md">
              <div className="scale-75 text-lightgreen ">
                < FaFacebookF />
              </div>
            </a>
          </div>
          <div className="pt-[10px] px-4">
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className=" text-white w-7 h-7 hover:text-peagreen">
              <FaInstagram className='scale-[1.35]' />
            </a>
          </div>
          <div className="py-2 flex justify-center items-center">
            <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer" className="w-7 h-7 bg-white hover:bg-peagreen flex justify-center items-center rounded-md">
              <div className="scale-75 text-lightgreen ">
                <FaTiktok />
              </div>
            </a>
          </div>
        </nav>
      </div> */}
      <div className='bg-darkgreen mt-1 py-3 text-center  text-sm text-white'>
        <p className="scale-75">
          &copy; <strong>2024 PURE & Vegan. ALL RIGHTS RESERVED.</strong> 
          {/* <a href="/privacy-policy" className="text-white font-bold hover:text-peagreen">PRIVACY POLICY</a> */}
        </p>
      </div>
    </footer>
  );
};
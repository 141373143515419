import { forwardRef } from "react";

const Health = forwardRef((props, ref) => {
  return (
    <>
      <h2 id="health" ref={ref} className="bg-lightgreen pl-3 sm:pl-16 text-white font-extrabold py-6 text-5xl">HEALTH</h2>
      <div className="flex justify-center items-center px-6 md:px-12 lg:px-20 xl:px-32 2xl:px-56 py-12">
        <p className="text-center text-darkgreen font-bold">
          Our products are a testament to the power of plant-based nutrition,
          offering a healthy and delicious alternative to traditional meat.
          High in fiber and packed with wholesome ingredients, our range is designed to nourish your body and satisfy your taste buds.
          Take a closer look at the ingredients that make our products stand out:
        </p>
      </div>
    </>
  );
});

export default Health;
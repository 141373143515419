import { useState } from "react";
import { forwardRef } from "react";
import emailjs from '@emailjs/browser';
import { IoIosSync } from 'react-icons/io';
import xss from 'xss';

const initForm = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  message: '',
  joinMovement: false,
  reachOut: false,
  mediaRequest: false,
}

const ContactUs = forwardRef((props, ref) => {
  const [isFirstNameFocused, setIsFirstNameFocused] = useState(false);
  const [isLastNameFocused, setIsLastNameFocused] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);
  const [isMessageFocused, setIsMessageFocused] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState(initForm);
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setError('');
    setFormData(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
  };

  const validateForm = () => {
    const { firstName, lastName, email, phone, message } = formData
    let tempError = '';

    if (!firstName || !lastName) {
      tempError = 'Name is required.';
    }

    if (!email || !email.includes('@')) {
      tempError = 'Email is required.';
    }

    if (!message) {
      tempError = 'Message is required.';
    }

    if ((firstName && firstName.length > 50) ||
      (lastName && lastName.length > 50) ||
      (email && email.length > 100) ||
      (phone && phone.length > 50) ||
      (message && message.length > 1500)) {
      tempError = 'Too long input.';
    }

    setError(tempError);
    return tempError;
  };

  const options = {
    whiteList: {},
    stripIgnoreTag: true,
    stripIgnoreTagBody: ["script"]
  };

  const cleanFormData = (data) => {
    const cleanedData = {};
    Object.keys(data).forEach(key => {
      if (typeof data[key] === 'string') {
        cleanedData[key] = xss(data[key].trim(), options);
      } else {
        cleanedData[key] = data[key];
      }
    });
    return cleanedData;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setIsLoading(true);
      const cleanedData = cleanFormData(formData);
      emailjs.send('service_ihevrcw', 'template_phrm3u4', cleanedData, 'b4JPSFCg_99nOSk-T')
        .then((result) => {
          setIsSent(true);
          setIsLoading(false);
        }, (error) => {
          setError('Try again later.');
          setIsLoading(false);;
        });
    }

  }

  return (
    <>
      <h2 id="contact" ref={ref} className="text-4xl sm:text-5xl font-extrabold pl-6 sm:pl-16 py-10 text-lightgreen">CONTACT US</h2>
      <div className="flex w-full bg-darkgreen flex-col md:flex-row">
        <div className="sm:py-20 w-full md:w-1/2 p-4 sm:p-10 py-8 sm:pt-20 order-1">
          <form onSubmit={sendEmail} className="flex flex-col space-y-4">
            <div className="flex sm:space-x-4">
              <input
                type="text"
                name="firstName"
                placeholder={!isFirstNameFocused ? "FIRST NAME" : ''}
                value={formData.firstName}
                onChange={handleChange}
                onFocus={() => setIsFirstNameFocused(true)}
                onBlur={() => setIsFirstNameFocused(false)}
                className="p-2 w-1/2 mr-4 flex-1 placeholder-darkgreen placeholder:font-bold placeholder:text-sm placeholder-opacity-100 offset-shadow"
              />
              <input
                type="text"
                name="lastName"
                placeholder={!isLastNameFocused ? "LAST NAME" : ''}
                value={formData.lastName}
                onChange={handleChange}
                onFocus={() => setIsLastNameFocused(true)}
                onBlur={() => setIsLastNameFocused(false)}
                className="p-2 w-1/2 flex-1 placeholder-darkgreen placeholder:font-bold placeholder:text-sm placeholder-opacity-100 offset-shadow"
              />
            </div>
            <div className="flex sm:space-x-4">
              <input
                type="email"
                name="email"
                placeholder={!isEmailFocused ? "EMAIL" : ''}
                value={formData.email}
                onChange={handleChange}
                onFocus={() => setIsEmailFocused(true)}
                onBlur={() => setIsEmailFocused(false)}
                className="p-2 w-1/2 mr-4 flex-1 placeholder-darkgreen placeholder:font-bold placeholder:text-sm placeholder-opacity-100 offset-shadow"
              />
              <input
                type="tel"
                name="phone"
                placeholder={!isPhoneFocused ? "PHONE" : ''}
                value={formData.phone}
                onChange={handleChange}
                onFocus={() => setIsPhoneFocused(true)}
                onBlur={() => setIsPhoneFocused(false)}
                className="p-2 w-1/2 flex-1 placeholder-darkgreen placeholder:font-bold placeholder:text-sm placeholder-opacity-100 offset-shadow"
              />
            </div>
            <textarea
              name="message"
              placeholder={!isMessageFocused ? "MESSAGE" : ''}
              value={formData.message}
              onChange={handleChange}
              onFocus={() => setIsMessageFocused(true)}
              onBlur={() => setIsMessageFocused(false)}
              className="p-2 w-full resize-none placeholder-darkgreen placeholder:font-bold placeholder:text-sm placeholder-opacity-100 offset-shadow"
            />
            <div className="inline-block">
              {error && (
                <div className="font-bold inline-block text-red-500 text-xs sm:text-base p-1 left-0 float-right">
                  {error}
                </div>
              )}
              {isSent && (
                <div className="font-bold inline-block text-peagreen text-xs sm:text-base p-1 left-0 float-right">
                  Message is sent.
                </div>
              )}
              <div className="flex flex-col pb-2 sm:pb-12 text-xs sm:text-base font-bold text-white">
                <p className="py-2 sm:py-5">WHAT WOULD YOU LIKE TO DO?</p>
                <label className="inline-flex items-center">
                  <input type="checkbox" name="joinMovement" checked={formData.joinMovement} onChange={handleChange} className="form-checkbox accent-peagreen" />
                  <span className="ml-1 my-[6px] md:my-0">JOIN THE MOVEMENT</span>
                </label>
                <label className="inline-flex items-center">
                  <input type="checkbox" name="reachOut" checked={formData.reachOut} onChange={handleChange} className="form-checkbox accent-peagreen" />
                  <span className="ml-1 my-[6px] md:my-0">REACH OUT TO US</span>
                </label>
                <label className="inline-flex items-center">
                  <input type="checkbox" name="mediaRequest" checked={formData.mediaRequest} onChange={handleChange} className="form-checkbox accent-peagreen" />
                  <span className="ml-1 my-[6px] md:my-0">SUBMIT A MEDIA REQUEST</span>
                </label>
              </div>
            </div>
            <button
              type="submit"
              disabled={isSent || isLoading}
              className="bg-lightgreen hover:bg-peagreen text-white font-bold py-1 w-20 flex justify-center items-center disabled:bg-slate-200"
            >
              {isLoading ? (
                <IoIosSync className="animate-spin" /> // Display spinning icon when loading
              ) : (
                'SEND'
              )}
            </button>
          </form>
        </div>
        <img
          src="/175436047_xl_normal_none_RET.jpg"
          alt="Sustainability"
          className="w-full md:w-[51%] object-cover order-2"
        />
      </div>
    </>
  );
});

export default ContactUs;
export default function PureLogo() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 841.9 595.3"
      style={{ enableBackground: "new 0 0 841.9 595.3", fill: "#FFFFFF" }}
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path class="st0" d="M596,281.9c-12.5-34.4-38.8-55-67.3-53.3c-28.5,1.7-37.2,33.1-37.2,33.1s34.2-11.7,49.1,19.5c11,23.2,0,59.3,43.5,64.3c23.7,2.8,43.3-24,48.1-35.2C632.2,310.3,608.5,316.3,596,281.9z" />
          <path class="st0" d="M367.3,256.3c0,30.8-11.3,51.2-40.8,51.2c-29.8,0-41.7-20.4-41.7-51.2V124.2h-43.3v129c-0.3,70,38.3,92.6,85.1,92.6c46.5,0,84.1-22.6,84.1-92.6v-129h-43.3V256.3z" />
          <path class="st0" d="M558.8,194.5c0,8.3-2.6,15.2-7.2,20.5c12.3,3.5,23.8,10.3,33.7,19.9c9.2-9.4,16.2-23,16.2-41.7c0-49.3-28.9-69.1-82.9-69.1h-77.2V235v108.9h43.3v-67.7v-14.6v-23V235v-6v-69.7H517C543.4,159.3,558.8,172.5,558.8,194.5z" />
          <path class="st0" d="M135.8,124.2H109h-5.8c-23.9,0-43.3,19.4-43.3,43.3v10.1v120.2v2.8c0,23.9,19.4,43.3,43.3,43.3l0-46.3V272v-34.5v-78.2h29.2c31.1,0,45.5,16.3,45.5,39.9c0,25.1-15.1,38.3-44,38.3h-5.8V272h13.3c44.6,0,80.1-22.6,80.1-72.8C221.5,148.7,188.9,124.2,135.8,124.2z" />
          <rect x="711.3" y="217.7" class="st0" width="50.1" height="34.5" />
          <path class="st0" d="M686.4,308.8v-56.5v-34.5v-58.4h57.2h38.2c0-19.4-15.7-35.2-35.1-35.2h-3H643v219.7h91.7h11.9c19.4,0,35.1-15.7,35.1-35.1l-48.4,0H686.4z" />
        </g>
        <g>
          <g>
            <rect x="391.5" y="410.8" class="st0" width="22.7" height="15.8" />
            <path class="st0" d="M379.3,452.6v-26v-15.8V384h26.1h18.8c0-8.9-7.7-16.2-17.2-16.2h-1.5h-47.4v101h43.1h5.8c9.5,0,17.2-7.2,17.2-16.2l-23.7,0H379.3z" />
          </g>
          <path class="st0" d="M219.8,468.1c-3.5-3.5-6.8-7-10.8-11.5c-10.4,8.9-23.1,13.8-38.9,13.8c-30.5,0-45.6-19.1-45.6-39.1c0-18.5,10.9-31.7,26.6-40.1v-0.6c-6.6-7.8-11.1-17.9-11.1-27.8c0-17.3,13.1-35.6,38.7-35.6c19.4,0,34.2,12.2,34.2,31.1c0,14.4-8.2,26.1-28.4,36.2l-0.2,0.8c9.6,11.1,20.3,23.5,27.8,31.7c5.9-9.3,10-22.2,12.5-36.8H247c-3.7,21.4-10,38.9-21.5,52.1c7.6,8.4,15.1,16.9,23.7,25.9H219.8z M174,451.9c9.6,0,17.8-4.1,22.9-9.5c-8.4-8.9-21.5-23.5-34.4-38.5c-6.5,4.5-14.1,11.7-14.1,23.3c0,13.8,10,24.7,25.4,24.7H174z M177.3,343.4c-9.4,0-14.5,8.2-14.5,16.9c0,8.4,3.9,15.2,10,22.2c11.7-7.4,18.4-13.8,18.4-23.5c0-7.4-4.1-15.6-13.7-15.6H177.3z" />
          <path class="st0" d="M482.8,415.4h35.7v14.5v31.9c-10.3,5.6-21.2,8.5-32.8,8.5c-7.9,0-15.1-1.1-21.4-3.2c-6.4-2.1-11.8-5.4-16.2-9.6c-4.4-4.3-7.8-9.7-10.2-16.3c-2.4-6.6-3.6-14.2-3.7-22.8c0-6.3,0.9-12.6,2.8-18.7c1.9-6.1,4.9-11.7,9-16.7c4.1-5,9.5-9,16.2-12c6.7-3,14.6-4.4,23.6-4.4c10.8,0,20.4,2,28.8,6.1l-5.6,14.8c-3.6-1.6-7.1-2.7-10.7-3.5c-3.6-0.8-7.7-1.2-12.4-1.2c-4.4,0-8.6,0.7-12.3,2.1c-3.8,1.4-7,3.6-9.8,6.5c-2.8,2.9-4.9,6.7-6.5,11.3c-1.6,4.6-2.4,9.8-2.4,15.8c0,4.5,0.5,8.9,1.6,13.2c1,4.3,2.8,8.2,5.2,11.5c2.4,3.3,5.6,6,9.6,8c4,2,8.9,3,14.6,3c5.6,0,10.6-1,15-3.1v-21.1h-17.8L482.8,415.4z" />
          <path class="st0" d="M601.1,468.7l-7.8-24.6h-38.5l-8,24.6h-20.9l35.3-100.9h25.7l35.1,100.9H601.1z M559.4,429.3h28.9l-9-28.2l-4.9-15.2h-1c-0.8,2.9-2.4,8-4.7,15.2L559.4,429.3z" />
          <path class="st0" d="M717.2,452.4c0,9-7.8,16.4-17.5,16.4h0L654,401.5h-1v67.3h-19.6V367.8h18.4l44.9,66.9h1l-0.1-6.4l-0.1-60.4c10.9,0,19.7,8.3,19.7,18.5v2.8v59.8V452.4z" />
          <path class="st0" d="M278.2,387.3l16.3,47.5l4.7,15.2h1l4.9-15.2l22.8-67h20.2v2l-35.6,98.9h-25.6l-35.6-98.9v-2h0.1c12.3,0,22.6,7.5,26.2,17.9L278.2,387.3z" />
        </g>
      </g>
    </svg>
  );
}
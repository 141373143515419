import NavBar from "./components/NavBar";
import AboutUs from "./components/AboutUs";
import OurProducts from "./components/OurProducts";
import Health from "./components/Health";
import IngredientsList from "./components/IngredientList";
import Sustainability from "./components/Sustainability";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import { useRef } from 'react';
import useScrollSpy from './hooks/hooks';

function App() {
  const aboutSection = useRef(null);
  const productSection = useRef(null);
  const healthSection = useRef(null);
  const sustainabilitySection = useRef(null);
  const contactSection = useRef(null);

  useScrollSpy([aboutSection, productSection, sustainabilitySection, contactSection]);

  return (
    <>
      <NavBar />
      <AboutUs ref={aboutSection} />
      <OurProducts ref={productSection} />
      <Health ref={healthSection} />
      <IngredientsList />
      <Sustainability ref={sustainabilitySection} />
      <ContactUs ref={contactSection} />
      <Footer />
    </>
  );
}

export default App;
